import {useEffect, useState} from "react";
import {useBetween} from "use-between";
import AbstractIndexedLoader, {
    IndexedLoaderObjectInterface
} from "./AbstractIndexedLoader";
import TicketApi from "../../Calls/TicketApi";

const cache = new AbstractIndexedLoader();

function TicketsAccepted() {
    const [ticketsAccepted, setTicketsAccepted] = useState<IndexedLoaderObjectInterface>({});
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = (locationId: string) => {
        return TicketApi.fetchLocationTickets(locationId, 'filterOnAccepted');
    };

    const fetch = (locationId: string, forceRefresh: boolean = false) => {
        cache.load([locationId], apiCall, forceRefresh);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                setTicketsAccepted(() => ({...cache.get()}));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    const getTicketsAcceptedByLocationId = (locationId: string) => {
        return ticketsAccepted[locationId]?.data;
    }

    return {fetch, ticketsAccepted, isLoading, getTicketsAcceptedByLocationId};
}

export const TicketsAcceptedLoader = () => useBetween(TicketsAccepted);