import Translator from "../../../Utils/Translator";
import Label from "../Label/Label";

interface InputInterface {
    inputData: any;
    onChange: (e: any) => void;
    label?: string|{
        id: string,
        variables: any,
    };
    name: string;
    checked?: boolean;
}

function Input({inputData, onChange, name, label, checked}: InputInterface) {
    if (checked === undefined) {
        checked = inputData[name].value;
    }

    if (label === undefined) {
        label = '';
    }
    if (typeof label !== 'string') {
        label = Translator.translate(label);
    }

    return <div className={`flex items-center gap-[10px]`}>
        <input
            type={`checkbox`}
            name={name}
            value={inputData.value}
            onChange={onChange}
            checked={checked}
            id={name}
        />
        <Label label={label} htmlFor={name}/>
    </div>;
}

export default Input;