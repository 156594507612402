import {ReactComponent as ThinLeftArrow} from "../../../../Icons/ThinLeftArrow.svg";
import {ReactComponent as Shop} from "../../../../Icons/Shop.svg";
import {ReactComponent as Bars3} from "../../../../Icons/Bars3.svg";
import {ReactComponent as User} from "../../../../Icons/User.svg";
import ToggleHook from "../../../../Hooks/Toggler/TogglerHook";
import {useEffect, useState} from "react";
import TicketUserDetailsModal from "../TicketUserDetailsModal/TicketUserDetailsModal";
import TicketDetailsModal from "../TicketDetailsModal/TicketDetailsModal";
import {TicketsLoader} from "../../../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {CallTicketUseCase} from "../../../../UseCases/Ticket/CallTicketUseCase";
import {WaitTicketUseCase} from "../../../../UseCases/Ticket/WaitTicketUseCase";
import {DeclineTicketUseCase} from "../../../../UseCases/Ticket/DeclineTicketUseCase";
import {AcceptTicketUseCase} from "../../../../UseCases/Ticket/AcceptTicketUseCase";
import {TicketsToValidateLoader} from "../../../../Api/Loaders/IndexedLoaders/TicketsToValidateLoader";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {
    CTA_TICKET_ACTION_ACCEPT,
    CTA_TICKET_ACTION_CALL, CTA_TICKET_ACTION_CALL_NO_COUNT, CTA_TICKET_ACTION_DECLINE, CTA_TICKET_ACTION_WAIT,
    CTA_TICKET_RELATED_DETAIL,
    CTA_TICKET_USER_DETAIL,
} from "../../../../Constants/Labels";
import {
    backgroundColor, boldText,
    border,
    classes, cursor,
    flex,
    margin,
    padding, position,
    round, textColor,
    textSize, width
} from "../../../../Theme/Theme";
import Label from "../../../Common/Label/Label";

interface TicketCardInterface {
    ticketId: string;
    index: number;
    displayDirection: boolean;
}

function TicketInQueueCard({ticketId, index, displayDirection}: TicketCardInterface) {
    const {toggleVisibility, isVisible, ref} = ToggleHook();
    const [userDetailedModalOpened, setUserDetailedModalOpened] = useState<boolean>(false);
    const [detailedModalOpened, setDetailedModalOpened] = useState<boolean>(false);
    const {fetch: fetchTickets, getTicketById} = TicketsLoader();
    const callTicketUseCase = CallTicketUseCase();
    const waitTicketUseCase = WaitTicketUseCase();
    const declineTicketUseCase = DeclineTicketUseCase();
    const acceptTicketUseCase = AcceptTicketUseCase();
    const {fetch: fetchTicketsToValidate} = TicketsToValidateLoader();

    useEffect(() => {
        fetchTickets([ticketId]);
    }, []);

    const ticket = getTicketById(ticketId);
    if (ticket === undefined) {
        return null;
    }

    return <>
        {userDetailedModalOpened && <TicketUserDetailsModal ticket={ticket} setIsOpened={setUserDetailedModalOpened}/>}
        {detailedModalOpened && <TicketDetailsModal ticket={ticket} setIsOpened={setDetailedModalOpened}/>}
        <div className={classes(!displayDirection ? margin('b', '10px') : '')}>
            {displayDirection && <div className={classes(flex("center", "center"), padding('xy', '5px'), border(), backgroundColor("secondary"), round(), textColor())}>
                {index > 0 ?
                    <ThinLeftArrow height={`20px`}/>
                    : <Shop height={`20px`}/>
                }
            </div>}
            <div className={classes(displayDirection ? padding('y', '5px') : '')}>
                <div className={classes(backgroundColor('secondary'), border(), round())}>
                    <div className={classes(flex("center", "between"), padding('xy', '10px'), border('b'))}>
                        <LabelNewLine label={ticket.userName} className={classes(boldText(), textSize('20px'))}/>
                        <div className={classes(flex("center", 'right', '5px'))}>
                            <div className={classes(border(), round(), cursor("pointer"), padding('xy', '10px'), flex("center", "left", '5px'))} onClick={toggleVisibility}><User height={`20px`}/> {ticket.peopleNumber}</div>
                            <div ref={ref}>
                                <div className={classes(border(), round(), cursor("pointer"), padding('xy', '10px'))} onClick={toggleVisibility}><Bars3 height={`20px`}/></div>
                                {isVisible && <div className={classes(round(), margin('t', '5px'), border(), backgroundColor("primary"), position('absolute'), margin('l', '-120px'), width('152px'))}>
                                    <div className={classes(backgroundColor("primary", true), padding('xy', '10px'), round())} onClick={() => setUserDetailedModalOpened(() => true)}>
                                        <Label label={CTA_TICKET_USER_DETAIL}/>
                                    </div>
                                    <div className={classes(backgroundColor("primary", true), padding('xy', '10px'), round(), border('t'))} onClick={() => setDetailedModalOpened(() => true)}>
                                        <Label label={CTA_TICKET_RELATED_DETAIL}/>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className={classes(flex("top", "center", '5px'), padding('xy', '10px'))}>
                        <div className={classes(round(), flex("center", "center"), width('48%'), padding('xy', '5px'), border(), backgroundColor("primary", true))} onClick={() => callTicketUseCase.execute(ticket.locationId, ticketId, ticket.waitingCount)}>
                            <Label label={ticket.waitingCount > 0 ? CTA_TICKET_ACTION_CALL(ticket.waitingCount) : CTA_TICKET_ACTION_CALL_NO_COUNT}/>
                        </div>
                        <div className={classes(round(), flex("center", "center"), width('48%'), padding('xy', '5px'), border(), backgroundColor("primary", true))} onClick={() => acceptTicketUseCase.execute(ticket.locationId, ticketId, () => fetchTicketsToValidate(ticket.locationId, true))}>
                            <Label label={CTA_TICKET_ACTION_ACCEPT}/>
                        </div>
                        <div className={classes(round(), flex("center", "center"), width('48%'), padding('xy', '5px'), border(), backgroundColor("primary", true))} onClick={() => waitTicketUseCase.execute(ticket.locationId, ticketId, () => fetchTicketsToValidate(ticket.locationId, true))}>
                            <Label label={CTA_TICKET_ACTION_WAIT}/>
                        </div>
                        <div className={classes(round(), flex("center", "center"), width('48%'), padding('xy', '5px'), border(), backgroundColor("primary", true))} onClick={() => declineTicketUseCase.execute(ticket.locationId, ticketId, () => fetchTicketsToValidate(ticket.locationId, true))}>
                            <Label label={CTA_TICKET_ACTION_DECLINE}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default TicketInQueueCard;