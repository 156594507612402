import {UpdateLocationUseCase} from "../../../../UseCases/Location/UpdateLocationUseCase";
import {useEffect} from "react";
import CheckboxAllowComments from "../Common/Form/Checkbox/CheckboxAllowComments";
import {
    LABEL_CONFIGURATION_TITLE, LABEL_ERROR,
    LABEL_LOCATION_COMMENT,
    LABEL_LOCATION_STYLE, LABEL_LOCATION_UPDATE_TO_SCHEDULE_LINK, LABEL_LOCATION_UPDATE_TO_TICKETS_LINK, LABEL_SUCCESS,
    LABEL_SYSTEM_TITLE
} from "../../../../Constants/Labels";
import InputCoverImageBackground from "../Common/Form/Input/InputCoverImageBackground";
import InputShortcutBackground from "../Common/Form/Input/InputShortcutBackground";
import InputShortcutText from "../Common/Form/Input/InputShortcutText";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import CheckboxShowComments from "../Common/Form/Checkbox/CheckboxShowComments";
import InputShortDescription from "../Common/Form/Input/InputShortDescription";
import TextareaLongDescription from "../Common/Form/Textarea/TextareaLongDescription";
import InputName from "../Common/Form/Input/InputName";
import InputAddress from "../Common/Form/Input/InputAddress";
import InputAdditionalAddress from "../Common/Form/Input/InputAdditionalAddress";
import InputCity from "../Common/Form/Input/InputCity";
import InputZipCode from "../Common/Form/Input/InputZipCode";
import InputPhoneNumber from "../Common/Form/Input/InputPhoneNumber";
import InputContactEmail from "../Common/Form/Input/InputContactEmail";
import {LocationsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import CheckboxQueueEmergencyStop from "../Common/Form/Checkbox/CheckboxQueueEmergencyStop";
import {getTextSize} from "../../../../Helpers/Text";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {useParams} from "react-router-dom";
import {getIcon} from "../../../../Helpers/Icon";
import ImageUploader from "../ImageUploader/ImageUploader";
import {
    backgroundColor, boldText,
    border,
    classes,
    cursor,
    flex,
    margin,
    padding,
    round, textColor,
    textSize,
    width
} from "../../../../Theme/Theme";
import Link from "../../../Common/Link/Link";

export interface UpdateLocationFormInterface {
    locationId: string;
}

function UpdateForm({locationId}: UpdateLocationFormInterface) {
    const {id} = useParams();
    const updateLocationUseCase = UpdateLocationUseCase();
    const {form, handleChange, handleBlur, handleFocus, setLocationId, handleChangeCheckbox} = UpdateLocationUseCase();
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        setLocationId(locationId);
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);
    if (undefined === location) {
        return null;
    }

    return <div className={classes(width('100%'))}>
        <div className={classes(padding('xy', '10px'), backgroundColor('secondary'), border(), round(), flex("center", "left", '10px'))}>
            <LabelNewLine boldText={true} label={location.name} className={classes(textSize('30px'))}/>&nbsp; {location.isQueueOpened && !location.queueEmergencyStop ? <LabelNewLine className={classes(round(), border(), padding('xy', '10px'), boldText())} textColor={"success"} label={LABEL_SUCCESS('restaurant_queue_opened')}/> : <LabelNewLine className={classes(round(), border(), padding('xy', '10px'), boldText())} textColor={'error'} label={LABEL_ERROR(!location.queueEmergencyStop ? 'restaurant_queue_closed' : 'restaurant_queue_emergency_closed')}/>}
        </div>
        <div className={classes(border(), padding('xy', '10px'), backgroundColor("secondary"), margin('t', '10px'), round())}>
            <Link to={`/locations/${id}/schedule`}>
                <div className={classes(flex('top', 'left', '10px'), cursor("pointer"))}>
                    <div>{getIcon('ArrowRight', 'extra-small')}</div>
                    <LabelNewLine className={classes(textSize('18px'))} label={LABEL_LOCATION_UPDATE_TO_SCHEDULE_LINK}/>
                </div>
            </Link>
            <Link to={`/locations/${id}/tickets`}>
                <div className={classes(flex('top', 'left', '10px'), cursor("pointer"))}>
                    <div>{getIcon('ArrowRight', 'extra-small')}</div>
                    <LabelNewLine className={classes(textSize('18px'))} label={LABEL_LOCATION_UPDATE_TO_TICKETS_LINK}/>
                </div>
            </Link>
        </div>
        <ImageUploader location={location}/>
        <form onSubmit={updateLocationUseCase.execute}>
            <div className={classes(backgroundColor("secondary"), padding('xy', '10px'), round(), width('100%'), border(), margin('t', '10px'))}>
                <LabelNewLine className={classes(textSize('20px'))} label={LABEL_SYSTEM_TITLE}/>
                <div className={classes(margin('t', '10px'))}><CheckboxQueueEmergencyStop handleChangeCheckbox={handleChangeCheckbox} form={form}/></div>
                <div className={classes(flex('top', "between", '10px'))}>
                    <input type={'text'} value={location.timezone} className={classes(padding('xy', '10px'), border(), backgroundColor("primary"), round(), margin('t', '10px'))} disabled/>
                </div>
            </div>
            <div className={classes(padding('xy', '10px'), margin('t', '10px'), backgroundColor("secondary"), border(), width('100%'), round())}>
                <LabelNewLine className={classes(textSize('20px'))} label={LABEL_CONFIGURATION_TITLE}/>
                <div className={classes(flex('top', 'between', '10px', true, 'md'), margin('t', '10px'))}>
                    <div className={classes(width('48%', '', 'md'), width('100%'))}>
                        <InputName className={classes(width('100%'), backgroundColor("primary"), border())} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                        <InputAddress className={classes(margin('t', '10px'), width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                        <InputAdditionalAddress className={classes(margin('t', '10px'), width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                        <InputCity className={classes(margin('t', '10px'), width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                        <InputZipCode className={classes(margin('t', '10px'), width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                        <InputPhoneNumber className={classes(margin('t', '10px'), width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                        <InputContactEmail className={classes(margin('t', '10px'), width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                    </div>
                    <div className={classes(width('48%', '', 'md'), width('100%'))}>
                        <InputShortDescription className={classes(margin('t', '10px'), margin('t', '0', 'md'), width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                        <TextareaLongDescription className={classes(margin('t', '10px'), width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/>
                    </div>
                </div>
            </div>
            <div className={classes(margin('t', '10px'), border(), round(), backgroundColor("secondary"), padding('xy', '10px'))}>
                <LabelNewLine className={classes(textSize('20px'))} label={LABEL_LOCATION_COMMENT}/>
                <div className={classes(margin('t', '10px'))}>
                    <div><CheckboxAllowComments handleChangeCheckbox={handleChangeCheckbox} form={form}/></div>
                    <div><CheckboxShowComments form={form} handleChangeCheckbox={handleChangeCheckbox}/></div>
                </div>
            </div>
            <div className={classes(border(), margin('t', '10px'), backgroundColor("secondary"), padding('xy', '10px'), round())}>
                <LabelNewLine className={classes(textSize('20px'))} label={LABEL_LOCATION_STYLE}/>
                <div className={classes(margin('t', '10px'))}>
                    <InputCoverImageBackground className={classes(width('100%'), backgroundColor("primary"), border())} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                    <InputShortcutBackground className={classes(width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                    <InputShortcutText className={classes(width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleChange={handleChange} handleFocus={handleFocus} handleBlur={handleBlur}/>
                </div>
            </div>
            <div className={classes(margin('t', '10px'))}>
                {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
                {form.success !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.success) === 'object' ? form.error : LABEL_SUCCESS(form.success)} textColor={"success"}/>}
            </div>
            <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                <ButtonSubmit/>
            </div>
        </form>
    </div>;
}

export default UpdateForm;