import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {LABEL_LOCATION_CITY} from "../../../../../../Constants/Labels";

interface InputCityInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    size?: 'full';
    margin?: string;
    className?: string;
}

function InputCity({form, handleChange, handleBlur, handleFocus, size, margin, className}: InputCityInterface) {
    return <Input
        inputData={form.fields.city}
        name={'city'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOCATION_CITY)}
        size={size}
        className={className ?? ''}
    />
}

export default InputCity;