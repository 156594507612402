import Translator from "../../../../../../Utils/Translator";
import Checkbox from "../../../../../Common/Form/Checkbox";
import {LABEL_LOCATION_ALLOW_COMMENTS, LABEL_LOCATION_SHOW_COMMENTS} from "../../../../../../Constants/Labels";

interface CheckboxShowCommentsInterface {
    form: any;
    handleChangeCheckbox: any;
}

function CheckboxShowComments({form, handleChangeCheckbox}: CheckboxShowCommentsInterface) {
    return <Checkbox
        inputData={form.fields.showComments}
        name={'showComments'}
        onChange={handleChangeCheckbox}
        checked={form.fields.showComments.value}
        label={Translator.translate(LABEL_LOCATION_SHOW_COMMENTS)}
    />;
}

export default CheckboxShowComments;