import MenuLink from "../MenuLink";
import {State} from "../../../../../../Layout/Menu/State";
import {CTA_RESTAURANTS} from "../../../../../Constants/Labels";

function MenuLinkRestaurants() {
    const {toggle} = State();

    return <MenuLink
        link={'/locations'}
        label={CTA_RESTAURANTS}
        onClick={toggle}
    />
}

export default MenuLinkRestaurants;