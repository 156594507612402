import {useBetween} from "use-between";
import {useEffect, useState} from "react";
import {TicketsWaitingLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/TicketsWaitingLoader";
import {TicketsAcceptedLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/TicketsAcceptedLoader";
import {TicketsDeclinedLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/TicketsDeclinedLoader";
import {TicketsToValidateLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/TicketsToValidateLoader";

function Tickets() {
    const {fetch: fetchTicketsWaiting} = TicketsWaitingLoader();
    const {fetch: fetchTicketsAccepted} = TicketsAcceptedLoader();
    const {fetch: fetchTicketsDeclined} = TicketsDeclinedLoader();
    const {fetch: fetchTicketsToValidate} = TicketsToValidateLoader();
    const [locationId, setLocationId] = useState<string>();
    const [section, setSection] = useState<string>('queue');
    const [shouldRefreshTicketsToValidate, setShouldRefreshTicketsToValidate] = useState<boolean>(false);

    useEffect(() => {
        if (locationId !== undefined) {
            fetchTicketsToValidate(locationId);
            setShouldRefreshTicketsToValidate(() => true);
        }
    }, [locationId]);

    useEffect(() => {
        if (shouldRefreshTicketsToValidate && section === 'queue' && locationId !== undefined) {
            setShouldRefreshTicketsToValidate(() => false);
            const timer = setTimeout(() => {
                fetchTicketsToValidate(locationId, true);
                setShouldRefreshTicketsToValidate(() => true);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [shouldRefreshTicketsToValidate]);

    const changeSection = (name: string) => {
        if (locationId === undefined) {
            return;
        }

        setSection(() => name);

        switch (name) {
            case 'waiting':
                fetchTicketsWaiting(locationId);
                break;
            case 'accepted':
                fetchTicketsAccepted(locationId);
                break;
            case 'declined':
                fetchTicketsDeclined(locationId);
                break;
        }
    };

    return {setLocationId, changeSection, section};
}

export const State = () => useBetween(Tickets);