import {ApiManager} from "../../Utils/ApiManager";
import {
    CREATE_OWNER_FORM_VALIDATOR_URL, CREATE_TEMPORARY_OWNER_FORM_VALIDATOR_URL,
    OWNER_URL,
    OWNERS_URL, TEMPORARY_OWNERS_URL,
    UPDATE_OWNER_FORM_VALIDATOR_URL
} from "../../Constants/ApiUrls";
import AbstractApi from "./AbstractApi";

class OwnerApi {
    static async fetchCreateOwnerFormValidator() {
        try {
            const response = await ApiManager.get(CREATE_OWNER_FORM_VALIDATOR_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchCreateOwnerFormValidator');
        }
    }
    static async fetchTemporaryCreateOwnerFormValidator() {
        try {
            const response = await ApiManager.get(CREATE_TEMPORARY_OWNER_FORM_VALIDATOR_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchTemporaryCreateOwnerFormValidator');
        }
    }

    static async fetchUpdateOwnerFormValidator() {
        try {
            const response = await ApiManager.get(UPDATE_OWNER_FORM_VALIDATOR_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchUpdateOwnerFormValidator');
        }
    }

    static async createOwner(form: any) {
        try {
            const response = await ApiManager.post(OWNERS_URL(), {
                // @ts-ignore
                login: form.fields.login.value,
                // @ts-ignore
                email: form.fields.email.value
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'createOwner', {form});
        }
    }

    static async createTemporaryOwner(form: any) {
        try {
            const response = await ApiManager.post(TEMPORARY_OWNERS_URL(), {
                login: form.fields.login.value,
                email: form.fields.email.value,
                password: form.fields.password.value,
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'createTemporaryOwner', {form});
        }
    }

    static async updateOwner(ownerId: string, form: any) {
        try {
            const response = await ApiManager.put(OWNER_URL(ownerId), {
                // @ts-ignore
                login: form.fields.login.value,
                // @ts-ignore
                email: form.fields.email.value,
                // @ts-ignore
                firstname: form.fields.firstname.value,
                // @ts-ignore
                lastname: form.fields.lastname.value,
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'updateOwner', {ownerId, form});
        }
    }

    static async fetchOwners(keyword?: string) {
        try {
            const response = await ApiManager.get(OWNERS_URL(keyword));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchOwners', {keyword});
        }
    };

    static async fetchOwner(ownerId: string) {
        try {
            const response = await ApiManager.get(OWNER_URL(ownerId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchOwner', {ownerId});
        }
    };
}

export default OwnerApi;