import {useBetween} from "use-between";
import {MenuLocationCache} from "../../Caches/Domain/Location/MenuLocationCache";

function SelectMenuLocation() {
    const {save} = MenuLocationCache();

    const execute = (locationId: string) => {
        save(locationId);
    };

    return {execute};
}

export const SelectMenuLocationUseCase = () => useBetween(SelectMenuLocation);