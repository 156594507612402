import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {LABEL_LOCATION_ADDRESS} from "../../../../../../Constants/Labels";

interface InputAddressInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    size?: 'full';
    margin?: string;
    className?: string;
}

function InputAddress({form, handleChange, handleBlur, handleFocus, size, className}: InputAddressInterface) {
    return <Input
        inputData={form.fields.address}
        name={'address'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOCATION_ADDRESS)}
        size={size}
        className={className ?? ''}
    />
}

export default InputAddress;