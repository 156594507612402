import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {LABEL_LOCATION_NAME, LABEL_LOCATION_ZIP_CODE} from "../../../../../../Constants/Labels";

interface InputZipCodeInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    size?: 'full';
    margin?: string;
    className?: string;
}

function InputZipCode({form, handleChange, handleBlur, handleFocus, size, margin, className}: InputZipCodeInterface) {
    return <Input
        inputData={form.fields.zipCode}
        name={'zipCode'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOCATION_ZIP_CODE)}
        size={size}
        className={className ?? ''}
    />
}

export default InputZipCode;