import {useBetween} from "use-between";
import {SearchLocationsUseCase} from "./SearchLocationsUseCase";

function SearchLocationsReset() {
    const {setKeywordDebounce} = SearchLocationsUseCase();

    const execute = () => {
        setKeywordDebounce(() => '');
    }

    return {execute};
}

export const SearchLocationsResetUseCase = () => useBetween(SearchLocationsReset);