import {useBetween} from "use-between";
import {TicketsLoader} from "../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {TicketsAcceptedLoader} from "../../Api/Loaders/IndexedLoaders/TicketsAcceptedLoader";
import TicketApi from "../../Api/Calls/TicketApi";
import {GetUserIdUseCase} from "../User/GetUserIdUseCase";
import {sendChangeTicketStatusEvent} from "../../GoogleAnalytics/Events";

function AcceptTicket() {
    const {fetch: fetchTickets} = TicketsLoader();
    const {fetch: fetchTicketsAccepted} = TicketsAcceptedLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    const execute = (locationId: string, ticketId: string, callback?: any) => {
        (async function() {
            const response = await TicketApi.updateTicketStatus(locationId, ticketId, 'accepted');
            if (response !== undefined) {
                sendChangeTicketStatusEvent(getUserIdUseCase.execute(), ticketId, 'accept');
                fetchTickets([ticketId], true);
                fetchTicketsAccepted(locationId, true);
                if (undefined !== callback) {
                    callback();
                }
            }
        })();
    };

    return {execute};
}

export const AcceptTicketUseCase = () => useBetween(AcceptTicket);