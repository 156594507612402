import Translator from "../../../../../../Utils/Translator";
import Textarea from "../../../../../Common/Form/Textarea";
import {LABEL_LOCATION_LONG_DESCRIPTION} from "../../../../../../Constants/Labels";

interface TextareaLongDescriptionInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    className?: string;
}

function TextareaLongDescription({form, handleChange, handleBlur, handleFocus, className}: TextareaLongDescriptionInterface) {
    return <Textarea
        inputData={form.fields.longDescription}
        name={'longDescription'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOCATION_LONG_DESCRIPTION)}
        size={"full"}
        height={"full"}
        className={className ?? ''}
    />;
}

export default TextareaLongDescription;