import {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import LocationImageEntity from "../../../../Entities/LocationImageEntity";
import LocationEntity from "../../../../Entities/LocationEntity";
import ComponentDevice from "../../../Common/ComponentDevice/ComponentDevice";
import Label from "../../../Common/Label/Label";
import {CTA_DRAG_AND_DROP_HERE, CTA_DROP_HERE} from "../../../../Constants/Labels";
import LocationApi from "../../../../Api/Calls/LocationApi";
import AbstractApi from "../../../../Api/Calls/AbstractApi";
import abstractApi from "../../../../Api/Calls/AbstractApi";
import {
    backgroundColor,
    border,
    borderSize, borderStyle,
    classes, cursor,
    flex,
    height,
    margin,
    padding,
    round, width
} from "../../../../Theme/Theme";

interface LocationImageUploaderInterface {
    location: LocationEntity;
}

export interface ChildPropsInterface {
    getRootProps: any;
    getInputProps: any;
    isDragActive: boolean;
    images: LocationImageEntity[];
}

function ImageUploader({location}: LocationImageUploaderInterface) {
    const [images, setImages] = useState<LocationImageEntity[]>(location.images);

    const loadImages = async () => {
        if (location !== undefined) {
            const response = await LocationApi.fetchImages(location.id);
            if (AbstractApi.isSuccess(response)) {
                setImages(() => response.data);
            }
        }
    };

    const onDrop = useCallback(async (acceptedFiles: any) => {
        const formData = new FormData();
        const [file] = acceptedFiles;
        formData.append("file", file);

        const response = await LocationApi.uploadImage(location.id, formData);
        if (abstractApi.isSuccess(response)) {
            await loadImages();
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const propsToSend = {getRootProps, getInputProps, isDragActive, images};

    return <div className={classes(backgroundColor("secondary"), margin('t', '10px'), flex('top', 'left', '10px'), round(), padding('xy', '10px'), border())}>
        <div {...getRootProps()} className={classes(flex("center", "center"), height('180px'), width('180px'), border('xy'), borderStyle("dashed"), round(), padding('xy', '10px'), cursor("pointer"))}>
            <input {...getInputProps()} />
            {isDragActive ?
                <Label label={CTA_DROP_HERE}/>
                : (
                    <Label label={CTA_DRAG_AND_DROP_HERE}/>
                )}
        </div>
        {images !== undefined && images.map((image: LocationImageEntity, index: number) => <div key={`image-${index}`} className={classes(flex("center", "center"), width('180px'), height('180px'), border(), round(), cursor("pointer"))}>
            <img src={image.url} className={classes(width('100%', "max"), height('full', "max"))}/>
        </div>)}
    </div>;
}

export default ImageUploader;