import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {LABEL_LOCATION_SHORTCUT_TEXT} from "../../../../../../Constants/Labels";

interface InputShortcutTextInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    className?: string;
}

function InputShortcutText({form, handleChange, handleBlur, handleFocus, className}: InputShortcutTextInterface) {
    return <Input
        inputData={form.fields.shortcutText}
        name={'shortcutText'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOCATION_SHORTCUT_TEXT)}
        size={"full"}
        className={className ?? ''}
    />
}

export default InputShortcutText;