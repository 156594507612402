import {useBetween} from "use-between";
import {MenuLocationCache} from "../../Caches/Domain/Location/MenuLocationCache";

function RemoveMenuLocation() {
    const {remove} = MenuLocationCache();

    const execute = () => {
        remove();
    };

    return {execute};
}

export const RemoveMenuLocationUseCase = () => useBetween(RemoveMenuLocation);