import {useBetween} from "use-between";
import {useState} from "react";

function MenuLocation() {
    const [isSearchOpened, setIsSearchOpened] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string|undefined>(undefined);
    const [debounceKeyword, setDebounceKeyword] = useState<string|undefined>(undefined);

    const toggleSearchOpening = () => setIsSearchOpened(() => !isSearchOpened);

    return {isSearchOpened, toggleSearchOpening};
}

export const State = () => useBetween(MenuLocation);