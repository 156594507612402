import Button from "../../Button/Button";
import {IsLoggedInUseCase} from "../../../../UseCases/User/IsLoggedInUseCase";
import {State} from "../../../../../Layout/Menu/State";
import {CTA_LOG_IN} from "../../../../Constants/Labels";
import Link from "../../Link/Link";


function MenuUnloggedWithoutRegister() {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const {toggle} = State();

    if (isLoggedInUseCase.execute()) {
        return null;
    }

    return <div className={`flex gap-[10px] py-[20px] px-[10px]`}>
        <Link to={`/`} className={`w-full`}>
            <Button
                size={"full"}
                label={CTA_LOG_IN}
                onClick={toggle}
            />
        </Link>
    </div>;
}

export default MenuUnloggedWithoutRegister;