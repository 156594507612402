import {useEffect, useRef, useState} from "react";

function ToggleHook() {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLInputElement>(null);

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsVisible(false);
        }
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current !== null && !ref.current?.contains(event?.target as Node)) {
            setIsVisible(false);
        }
    }

    const toggleVisibility = () => {
        setIsVisible(() => !isVisible);
    }

    useEffect(() => {
     document.addEventListener('keydown', handleHideDropdown, true);
     document.addEventListener('click', handleClickOutside, true);
     return () => {
         document.removeEventListener('keydown', handleHideDropdown, true);
         document.removeEventListener('click', handleClickOutside, true);
     };
    });

    const open = () => {
        setIsVisible(() => true);
    }

    return {ref, isVisible, toggleVisibility, open};
}

export default ToggleHook;