import {LocalStorage} from "../../LocalStorage";
import {useState} from "react";
import {useBetween} from "use-between";

export default class MenuLocationStorage extends LocalStorage {
    constructor() {
        super('menu_location');
    }

    save(value: any): void {super.save(value)};
    get(): any|null {return super.get()};
}

function MenuLocationState() {
    const cachedData = new MenuLocationStorage().get();
    const [menuLocation, setMenuLocation] = useState<string|undefined>(cachedData === null || cachedData === undefined ? undefined : cachedData);

    const save = (value: string) => {
        setMenuLocation(() => value);
        new MenuLocationStorage().save(value);
    }

    const remove = () => {
        setMenuLocation(() => undefined);
        new MenuLocationStorage().remove();
    }

    return {menuLocation, save, remove};
}

export const MenuLocationCache = () => useBetween(MenuLocationState);