import {useBetween} from "use-between";
import {FormHook} from "../../Hooks/Form/FormHook";
import {useEffect} from "react";
import LocationApi from "../../Api/Calls/LocationApi";
import AbstractApi from "../../Api/Calls/AbstractApi";
import {GetUserIdUseCase} from "../User/GetUserIdUseCase";
import {sendCreateLocationFailEvent, sendCreateLocationSuccessEvent} from "../../GoogleAnalytics/Events";


function CreateLocation() {
    const {form, handleChange, handleBlur, handleFocus, shouldSubmit, setForm, setConstrains} = FormHook(['name']);
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        (async function () {
            const response = await LocationApi.fetchCreateLocationFormValidator();
            if (response.success) {
                setConstrains(response.data);
            }
        })();
    }, []);

    const execute = async (e: any) => {
        e.preventDefault();
        if (!shouldSubmit()) {
            sendCreateLocationFailEvent(getUserIdUseCase.execute());
            return;
        }

        setForm(() => ({
            ...form,
            isLoading: true,
            error: '',
            success: '',
        }))

        const response = await LocationApi.createLocation(form);
        if (AbstractApi.isSuccess(response)) {
            sendCreateLocationSuccessEvent(getUserIdUseCase.execute());
            document.location.href = `/locations/${response.data.id}`;
        } else {
            sendCreateLocationFailEvent(getUserIdUseCase.execute());
            setForm(() => ({
                ...form,
                success: '',
                error: response.data
            }));
        }
    };

    return {form, handleChange, handleBlur, handleFocus, execute};
}

export const CreateLocationUseCase = () => useBetween(CreateLocation);