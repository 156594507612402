import MenuOpener from "../../Libraries/Components/Common/Menu/MenuOpener/MenuOpener";
import {
    backgroundColor,
    border,
    borderColor,
    classes,
    flex,
    height,
    padding, position,
    textColor, width
} from "../../Libraries/Theme/Theme";
import ThemeButton from "../../Libraries/Components/Common/Button/ThemeButton";
import LocaleButton from "../../Libraries/Components/Common/Button/LocaleButton";
import {State} from "../Menu/State";

function Header() {
    const {isOpened} = State();

    return <div className={`${classes(position('fixed'), !isOpened ? width('100%') : width('100%-300px', '', 'md'), padding('xy', '10px'), textColor(), borderColor(), border('b'), height('70px'), flex('center', 'between'), backgroundColor('secondary'))}`}>
        <div>
            <MenuOpener/>
        </div>
        <div className={classes(flex("center", "center", '10px'))}>
            <LocaleButton/>
            <ThemeButton/>
        </div>
    </div>;
}

export default Header;