import {useBetween} from "use-between";
import {useEffect} from "react";
import {SearchLocationsResetUseCase} from "./SearchLocationsResetUseCase";

function AutoSearchLocations() {
    const searchLocationsResetUseCase = SearchLocationsResetUseCase();

    useEffect(() => {
        searchLocationsResetUseCase.execute();
    }, []);
}

export const AutoSearchLocationsUseCase = () => useBetween(AutoSearchLocations);