import {createContext, useState} from "react";
import MenuLocationCache from "../Caches/Domain/Location/MenuLocationCache";

export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const REMOVE_LOCATION = 'REMOVE_LOCATION';

const defaultContext = {
    location: new MenuLocationCache().get(),
    dispatch: (actionType: string, payload?: any): void => {}
};

export interface FastManageLocationContextInterface {
    location: any;
    dispatch: (actionType: string, payload?: any) => void;
}

export const FastManageLocationContext = createContext<FastManageLocationContextInterface>(defaultContext);
export const FastManageLocationContextProvider = FastManageLocationContext.Provider;

export const FastManageLocationContextHook = () => {
    const cache = new MenuLocationCache();
    const cacheData = cache.get();

    const [location, setLocation] = useState(cacheData);

    const dispatchLocation = (actionType: string, payload?: any): void => {
        switch (actionType) {
            case UPDATE_LOCATION:
                if (payload !== undefined) {
                    cache?.save(payload);
                    setLocation(payload);
                }
                break;
            case REMOVE_LOCATION:
                cache?.remove();
                setLocation(null);
                break;
        }
    }

    return [location, dispatchLocation];
}
