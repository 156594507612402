import {CreateLocationUseCase} from "../../../../UseCases/Location/CreateLocationUseCase";
import Label from "../../../Common/Label/Label";
import {LABEL_CREATE_LOCATION, LABEL_ERROR} from "../../../../Constants/Labels";
import InputName from "../Common/Form/Input/InputName";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {
    backgroundColor,
    boldText,
    border,
    classes,
    flex,
    margin,
    padding,
    round,
    textSize
} from "../../../../Theme/Theme";

function CreateForm() {
    const {form, handleChange, handleBlur, handleFocus} = CreateLocationUseCase();
    const createLocationUseCase = CreateLocationUseCase();

    return <div className={classes(border(),backgroundColor("secondary"), padding('xy', '10px'), round())}>
        <LabelNewLine className={classes(textSize('20px'), boldText())} label={LABEL_CREATE_LOCATION}/>
        <form onSubmit={createLocationUseCase.execute} className={classes(margin('t', '10px'))}>
            <div><InputName className={classes(border(), backgroundColor('primary'))} form={form} handleFocus={handleFocus} handleChange={handleChange} handleBlur={handleBlur}/></div>
            <div className={classes(margin('t', '10px'))}>
                {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
            </div>
            <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                <ButtonSubmit/>
            </div>
        </form>
    </div>;
}

export default CreateForm;