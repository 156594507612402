import {useEffect, useState} from "react";
import {useBetween} from "use-between";
import {ApiManager} from "../../Utils/ApiManager";
import {FormHook} from "../../Hooks/Form/FormHook";
import {LocationsLoader} from "../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {UPDATE_LOCATION_FORM_VALIDATOR_URL} from "../../Constants/ApiUrls";
import LocationApi from "../../Api/Calls/LocationApi";
import AbstractApi from "../../Api/Calls/AbstractApi";
import {GetUserIdUseCase} from "../User/GetUserIdUseCase";
import {sendUpdateLocationFailEvent, sendUpdateLocationSuccessEvent} from "../../GoogleAnalytics/Events";

function UpdateLocation() {
    const [locationId, setLocationId] = useState<undefined|string>(undefined);
    const {form, handleChange, handleBlur, handleFocus, shouldSubmit, setForm, setConstrains, setValues, handleChangeCheckbox} = FormHook(['name', 'address', 'shortDescription', 'longDescription', 'zipCode', 'city', 'additionalAddress', 'phoneNumber', 'contactEmail', 'allowComments', 'showComments', 'coverImageBackground', 'shortcutBackground', 'shortcutText', 'ownerId', 'queueEmergencyStop']);
    const {fetch: fetchLocations, locations, getLocationById} = LocationsLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        setForm(() => ({
            ...form,
            isLoading: true
        }));
        (async function () {
            try {
                const response = await ApiManager.get(UPDATE_LOCATION_FORM_VALIDATOR_URL);
                setConstrains(response.data);
            } catch (e: any) {
                console.error('Error while trying to fetch location update form validator', e);
            }

            setForm(() => ({
                ...form,
                isLoading: false
            }));
        })();
    }, []);

    useEffect(() => {
        if (undefined !== locationId) {
            fetchLocations([locationId]);
        }
    }, [locationId]);

    useEffect(() => {
        if (locationId !== undefined) {
            const location = getLocationById(locationId);
            if (location !== undefined) {
                setValues({
                    ...location,
                    allowComments: location.allowComments !== 0 ,
                    showComments: location.showComments !== 0,
                    queueEmergencyStop: location.queueEmergencyStop !== 0,
                    ownerId: location.owner?.id ?? ''
                });
            }
        }
    }, [locations]);

    const execute = async (e: any) => {
        e.preventDefault();
        if (!shouldSubmit() || locationId === undefined) {
            sendUpdateLocationFailEvent(getUserIdUseCase.execute());
            return;
        }

        setForm({
            ...form,
            isLoading: true,
        });

        const response = await LocationApi.updateLocation(locationId, form);
        if (AbstractApi.isSuccess(response)) {
            sendUpdateLocationSuccessEvent(getUserIdUseCase.execute(), locationId);
            setForm({
                ...form,
                isLoading: false,
                success: `success_update_location`,
                error: ''
            });
            fetchLocations([locationId], true);
        } else {
            sendUpdateLocationFailEvent(getUserIdUseCase.execute());
            setForm({
                ...form,
                isLoading: false,
                error: 'generic_error',
                success: ''
            });
        }
    };

    return {form, handleChange, handleBlur, handleFocus, execute, setValues, handleChangeCheckbox, setForm, setConstrains, setLocationId};
}

export const UpdateLocationUseCase = () => useBetween(UpdateLocation);