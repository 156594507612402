import {useBetween} from "use-between";
import {TicketsLoader} from "../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {TicketsDeclinedLoader} from "../../Api/Loaders/IndexedLoaders/TicketsDeclinedLoader";
import TicketApi from "../../Api/Calls/TicketApi";
import {GetUserIdUseCase} from "../User/GetUserIdUseCase";
import {sendChangeTicketStatusEvent} from "../../GoogleAnalytics/Events";

function DeclineTicket() {
    const {fetch: fetchTickets} = TicketsLoader();
    const {fetch: fetchTicketsDeclined} = TicketsDeclinedLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    const execute = (locationId: string, ticketId: string, callback?: any) => {
        (async function() {
            const response = await TicketApi.updateTicketStatus(locationId, ticketId, 'refused');
            if (response !== undefined) {
                sendChangeTicketStatusEvent(getUserIdUseCase.execute(), ticketId, 'decline');
                fetchTickets([ticketId], true);
                fetchTicketsDeclined(locationId, true);
                if (undefined !== callback) {
                    callback();
                }
            }
        })();
    };

    return {execute};
}

export const DeclineTicketUseCase = () => useBetween(DeclineTicket);