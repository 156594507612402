import Copy from "../../../Common/Copy/Copy";
import {useEffect} from "react";
import {CTA_COPIED, CTA_COPY_LINK, LABEL_RESET_PASSWORD} from "../../../../Constants/Labels";
import {FRONT__OWNER__CONFIGURE_PASSWORD_URL} from "../../../../Constants/FrontUrls";
import Translator from "../../../../Utils/Translator";
import {OwnersLoader} from "../../../../Api/Loaders/IndexedLoaders/OwnersLoader";
import Label from "../../../Common/Label/Label";

interface ConfigurePasswordInterface {
    ownerId: string;
}

function ResetPassword({ownerId}: ConfigurePasswordInterface) {
    const {fetch: fetchOwners, getOwnerById} = OwnersLoader();

    useEffect(() => {
        fetchOwners([ownerId]);
    }, []);

    const owner = getOwnerById(ownerId);

    if (owner === undefined) {
        return null;
    }

    return <div className={`mt-[10px] flex items-center gap-[5px]`}>
        {owner.requireConfiguration !== undefined ?
            <NeedToDo ownerId={ownerId}/>
            : <AlreadyDone/>
        }
    </div>
}

function AlreadyDone() {
    return <Label textColor={'success'} label={Translator.translate(LABEL_RESET_PASSWORD)}/>;
}

interface NeedToDoInterface {
    ownerId: string;
}

function NeedToDo({ownerId}: NeedToDoInterface) {
    const {fetch: fetchOwners, getOwnerById} = OwnersLoader();

    useEffect(() => {
        fetchOwners([ownerId]);
    }, []);

    const owner = getOwnerById(ownerId);
    if (owner === undefined || owner.requireConfiguration === undefined) {
        return null;
    }

    return <>
        <Label textColor={'error'} label={Translator.translate(LABEL_RESET_PASSWORD)}/>
        <Copy
            stringToCopy={FRONT__OWNER__CONFIGURE_PASSWORD_URL(owner.email, owner.requireConfiguration)}
            copiedLabel={Translator.translate(CTA_COPIED)}
            copyLabel={Translator.translate(CTA_COPY_LINK)}
        />
    </>
}

export default ResetPassword;