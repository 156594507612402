import Translator from "../../../../../../Utils/Translator";
import Checkbox from "../../../../../Common/Form/Checkbox";
import {LABEL_LOCATION_ALLOW_COMMENTS} from "../../../../../../Constants/Labels";

interface CheckboxAllowCommentsInterface {
    form: any;
    handleChangeCheckbox: any;
}

function CheckboxAllowComments({form, handleChangeCheckbox}: CheckboxAllowCommentsInterface) {
    return <Checkbox
        inputData={form.fields.allowComments}
        name={'allowComments'}
        onChange={handleChangeCheckbox}
        label={Translator.translate(LABEL_LOCATION_ALLOW_COMMENTS)}
        checked={form.fields.allowComments.value}
    />;
}

export default CheckboxAllowComments;