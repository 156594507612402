import TicketEntity from "../../../../Entities/TicketEntity";
import Modal from "../../../Common/Modal/Modal";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {border, classes, padding, textSize} from "../../../../Theme/Theme";
import {
    LABEL_PEOPLE_NUMBER,
    LABEL_TICKET_CODE, LABEL_TICKET_DATE,
    LABEL_TICKET_RELATED_DETAIL_TITLE, LABEL_TICKET_TIME,
} from "../../../../Constants/Labels";
import Label from "../../../Common/Label/Label";
import moment from "moment";

interface TicketDetailsModalInterface {
    ticket: TicketEntity;
    setIsOpened: any;
}
function TicketDetailsModal({ticket, setIsOpened}: TicketDetailsModalInterface) {
    return <Modal opened={true} onClickModalBackground={() => setIsOpened(() => false)}>
        <div>
            <LabelNewLine className={classes(textSize('20px'), padding('xy', '10px'), border('b'))} label={LABEL_TICKET_RELATED_DETAIL_TITLE}/>
            <div className={classes(padding('xy', '10px'))}>
                <div><Label label={LABEL_PEOPLE_NUMBER}/>: <span className={`font-bold`}>{ticket.peopleNumber}</span></div>
                <div><Label label={LABEL_TICKET_CODE}/>: <span className={`font-bold`}>{ticket.code}</span></div>
                <div><Label label={LABEL_TICKET_DATE}/>: <span className={`font-bold`}>{moment(ticket.creationDate.date).format('DD-MM-YYYY')}</span></div>
                <div><Label label={LABEL_TICKET_TIME}/>: <span className={`font-bold`}>{moment(ticket.creationDate.date).format('H:m:s')}</span></div>
            </div>
        </div>
    </Modal>;
}

export default TicketDetailsModal;