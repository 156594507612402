import Translator from "../../../../Utils/Translator";
import Copy from "../../../Common/Copy/Copy";
import Loading from "../../../Common/Loading/Loading";
import {UpdateOwnerUseCase} from "../../../../UseCases/Owner/UpdateOwnerUseCase";
import {OwnersLoader} from "../../../../Api/Loaders/IndexedLoaders/OwnersLoader";
import {useEffect} from "react";
import TodoList from "../TodoList/TodoList";
import InputLogin from "../Common/Form/Input/InputLogin";
import InputEmail from "../Common/Form/Input/InputEmail";
import InputFirstname from "../Common/Form/Input/InputFirstname";
import InputLastname from "../Common/Form/Input/InputLastname";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import {LABEL_CONFIGURATION_TITLE, LABEL_ERROR, LABEL_SUCCESS, LABEL_SYSTEM_TITLE} from "../../../../Constants/Labels";
import Label from "../../../Common/Label/Label";
import {
    backgroundColor,
    boldText,
    border,
    classes,
    flex, margin,
    padding,
    round,
    textSize,
    width
} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import Login from "../Common/Data/Login";

const TRANSLATION_PREFIX = 'queube__components_form_update_owner_update_owner_form__';

interface UpdateOwnerFormInterface {
    ownerId: string;
}

function UpdateForm({ownerId}: UpdateOwnerFormInterface) {
    const {fetch: fetchOwners, getOwnerById} = OwnersLoader();
    const {form, handleChange, handleFocus, handleBlur, setOwnerId} = UpdateOwnerUseCase();
    const updateOwnerUseCase = UpdateOwnerUseCase();

    useEffect(() => {
        setOwnerId(ownerId);
        fetchOwners([ownerId]);
    }, []);

    const owner = getOwnerById(ownerId);
    if (owner === undefined) {
        return null;
    }

    return <div className={classes(width('100%'))}>
        <div className={classes(padding('xy', '10px'), backgroundColor('secondary'), border(), round())}>
            <LabelNewLine className={classes(boldText(), textSize('30px'))} label={<Login ownerId={ownerId}/>}/>
        </div>
        <TodoList ownerId={ownerId}/>
        <form onSubmit={updateOwnerUseCase.execute}>
            <div className={classes(backgroundColor("secondary"), padding('xy', '10px'), round(), border())}>
                <LabelNewLine className={classes(textSize('20px'))} label={LABEL_SYSTEM_TITLE}/>
                <div className={classes(flex("center", 'between', '10px'), margin('t', '10px'))}>
                    <input className={classes(border(), backgroundColor("primary"), round(), padding('xy', '10px'), width('100%'), width('300px', '', 'sm'))} type={'text'} value={owner.timezone} disabled/>
                </div>
            </div>
            <div className={classes(backgroundColor('secondary'), border(), padding('xy', '10px'), margin('t', '10px'), round())}>
                <LabelNewLine className={classes(textSize('20px'))} label={LABEL_CONFIGURATION_TITLE}/>
                <div className={classes(margin('t', '10px'))}>
                    <div><InputLogin className={classes(width('100%'), width('300px', '', 'sm'), border(), backgroundColor("primary"))} form={form} handleBlur={handleBlur} handleChange={handleChange} handleFocus={handleFocus}/></div>
                    <div><InputFirstname className={classes(width('100%'), width('300px', '', 'sm'), border(), backgroundColor("primary"), margin('t', '10px'))} form={form} handleBlur={handleBlur} handleChange={handleChange} handleFocus={handleFocus}/></div>
                    <div><InputLastname className={classes(width('100%'), width('300px', '', 'sm'), border(), backgroundColor("primary"), margin('t', '10px'))} form={form} handleBlur={handleBlur} handleChange={handleChange} handleFocus={handleFocus}/></div>
                </div>
            </div>
            <div className={classes(margin('t', '10px'))}>
                {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
                {form.success !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.success) === 'object' ? form.error : LABEL_SUCCESS(form.success)} textColor={"success"}/>}
            </div>
            <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                <ButtonSubmit/>
            </div>
        </form>
    </div>;
}

export default UpdateForm;