import {useBetween} from "use-between";
import {TicketsLoader} from "../../Api/Loaders/IndexedLoaders/TicketsLoader";
import TicketApi from "../../Api/Calls/TicketApi";
import {GetUserIdUseCase} from "../User/GetUserIdUseCase";
import {sendChangeTicketStatusEvent} from "../../GoogleAnalytics/Events";

function CallTicket() {
    const {fetch: fetchTickets} = TicketsLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    const execute = (locationId: string, ticketId: string, currentCount: number) => {
        (async function() {
            const response = await TicketApi.callTicket(locationId, ticketId, currentCount);
            if (response !== undefined) {
                sendChangeTicketStatusEvent(getUserIdUseCase.execute(), ticketId, 'call');
                fetchTickets([ticketId], true);
            }
        })();
    };

    return {execute};
}

export const CallTicketUseCase = () => useBetween(CallTicket);