import {useEffect} from "react";
import {OwnersLoader} from "../../../../../Api/Loaders/IndexedLoaders/OwnersLoader";

interface LoginInterface {
    ownerId: string;
}

function Login({ownerId}: LoginInterface) {
    const {fetch: fetchOwners, getOwnerById} = OwnersLoader();

    useEffect(() => {
        fetchOwners([ownerId]);
    }, []);

    const owner = getOwnerById(ownerId);

    if (owner === undefined) {
        return null;
    }

    return owner.login;
}

export default Login;