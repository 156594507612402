import {useEffect, useState} from "react";
import {useBetween} from "use-between";
import AbstractIndexedLoader, {
    IndexedLoaderObjectInterface
} from "./AbstractIndexedLoader";
import LocationApi from "../../Calls/LocationApi";

const cache = new AbstractIndexedLoader();

function MenuLocationsSearches() {
    const [menuLocationsSearches, setMenuLocationsSearches] = useState<IndexedLoaderObjectInterface>({});
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = (keyword: string) => {
        return LocationApi.fetchFastLocations(keyword);
    };

    const fetch = (keyword: string, forceRefresh: boolean = false) => {
        cache.load([keyword], apiCall, forceRefresh);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                setMenuLocationsSearches(() => ({...cache.get()}));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    const getMenuLocationsSearchByKeyword = (keyword: string) => {
        return menuLocationsSearches[keyword]?.data;
    }

    return {fetch, menuLocationsSearches, isLoading, getMenuLocationsSearchByKeyword};
}

export const MenuLocationsSearchesLoader = () => useBetween(MenuLocationsSearches);