import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {
    LABEL_LOCATION_NAME,
    LABEL_LOCATION_PHONE_NUMBER,
    LABEL_LOCATION_ZIP_CODE
} from "../../../../../../Constants/Labels";

interface InputPhoneNumberInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    size?: 'full';
    margin?: string;
    className?: string;
}

function InputPhoneNumber({form, handleChange, handleBlur, handleFocus, size, margin, className}: InputPhoneNumberInterface) {
    return <Input
        inputData={form.fields.phoneNumber}
        name={'phoneNumber'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOCATION_PHONE_NUMBER)}
        size={size}
        className={className ?? ''}
    />
}

export default InputPhoneNumber;