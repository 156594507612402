import TicketEntity from "../../../../Entities/TicketEntity";
import {ReactComponent as CircledWarning} from "../../../../Icons/CircledWarning.svg";
import Modal from "../../../Common/Modal/Modal";
import Label from "../../../Common/Label/Label";
import {
    LABEL_FULLNAME,
    LABEL_LOGIN,
    LABEL_TICKET_USER_DETAIL_NO_ACCOUNT,
    LABEL_TICKET_USER_DETAIL_TITLE
} from "../../../../Constants/Labels";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {border, classes, flex, margin, padding, textSize} from "../../../../Theme/Theme";

interface TicketDetailsModalInterface {
    ticket: TicketEntity;
    setIsOpened: any;
}
function TicketUserDetailsModal({ticket, setIsOpened}: TicketDetailsModalInterface) {
    return <Modal opened={true} onClickModalBackground={() => setIsOpened(() => false)}>
        <div>
            <LabelNewLine className={classes(textSize('20px'), padding('xy', '10px'), border('b'))} label={LABEL_TICKET_USER_DETAIL_TITLE}/>
            <div className={classes(padding('xy', '10px'))}>
                {ticket.isManuallyCreated && <div className={classes(flex("center", "center", '5px'), margin('y', '10px'))}>
                    <CircledWarning className={`w-[20px]`}/> <Label label={LABEL_TICKET_USER_DETAIL_NO_ACCOUNT}/>
                </div>}
                <div>
                    {!ticket.isManuallyCreated && ticket.user !== null ? <>
                        <div><Label label={LABEL_LOGIN}/>: <span className={`font-bold`}>{ticket.user.login}</span></div>
                        <div><Label label={LABEL_FULLNAME}/>: <span className={`font-bold`}>{ticket.user.firstname} {ticket.user.lastname}</span></div>
                    </> : <>
                        <div><Label label={LABEL_FULLNAME}/>: <span className={`font-bold`}>{ticket.userName}</span></div>
                    </>}
                </div>
            </div>
        </div>
    </Modal>;
}

export default TicketUserDetailsModal;