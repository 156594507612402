import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {LABEL_LOCATION_NAME} from "../../../../../../Constants/Labels";

interface InputNameInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    className?: string;
}

function InputName({form, handleChange, handleBlur, handleFocus, className}: InputNameInterface) {
    return <Input
        inputData={form.fields.name}
        name={'name'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOCATION_NAME)}
        className={className ?? ''}
    />
}

export default InputName;