import {
    backgroundColor,
    classes,
    cursor,
    flex,
    margin,
    padding,
    textAlign,
    textSize,
    underline
} from "../../../../Theme/Theme";
import LabelNewLine from "../../Label/LabelNewLine";

interface LogoInterface {
    subLogo?: { id: string, variables: any }|string;
}

function Logo({subLogo}: LogoInterface) {
    return <div className={classes(flex("center", "center"), padding('xy', '10px'), cursor("pointer"), backgroundColor("primary", true))} onClick={() => document.location.href = '/'}>
        <div className={classes(textAlign('right'))}>
            <LabelNewLine label={'Queube'} className={classes(textSize('30px'))}/>
            {subLogo !== undefined && <LabelNewLine label={subLogo} className={classes(textAlign('right'), textSize('10px'), margin('t', '-10px'), underline())}/>}
        </div>
    </div>;
}

export default Logo;