import {ReactComponent as ArrowRight} from "../../../../Icons/ArrowRight.svg";
import Translator from "../../../../Utils/Translator";
import MenuLink from "../MenuLink/MenuLink";
import MenuSeparator from "../MenuSeparator/MenuSeparator";
import {State as MenuLocationState} from "./State";
import {MenuLocationCache} from "../../../../Caches/Domain/Location/MenuLocationCache";
import {State as MenuState} from "../../../../../Layout/Menu/State";
import {MenuLocationsSearchesLoader} from "../../../../Api/Loaders/IndexedLoaders/MenuLocationsSearchesLoader";
import {useEffect} from "react";
import {SearchMenuLocationsUseCase} from "../../../../UseCases/Location/SearchMenuLocationsUseCase";
import {LocationsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {SelectMenuLocationUseCase} from "../../../../UseCases/Location/SelectMenuLocationUseCase";
import {RemoveMenuLocationUseCase} from "../../../../UseCases/Location/RemoveMenuLocationUseCase";
import {IsLoggedInUseCase} from "../../../../UseCases/User/IsLoggedInUseCase";
import LabelNewLine from "../../Label/LabelNewLine";
import {
    CTA_CHOOSE_LOCATION, CTA_SCHEDULE,
    CTA_SETTINGS,
    CTA_TICKETS,
    LABEL_LOCATION_SEARCH_BY_KEYWORD
} from "../../../../Constants/Labels";
import {getIcon} from "../../../../Helpers/Icon";
import {getCorners} from "../../../../Helpers/Corners";
import {getWidth} from "../../../../Helpers/Width";
import {backgroundColor, border, classes, margin, padding, round, width} from "../../../../Theme/Theme";
import {sendChooseFastLocationEvent, sendRemoveFastLocationEvent} from "../../../../GoogleAnalytics/Events";
import {GetUserIdUseCase} from "../../../../UseCases/User/GetUserIdUseCase";

const TRANSLATION_PREFIX = 'admin__components_layout_menu_menu_location__';

function MenuLocation() {
    const isLoggedInUseCase = IsLoggedInUseCase();

    const {fetch: fetchLocations, getLocationById} = LocationsLoader();
    const {keyword, setKeywordDebounce, keywordDebounce} = SearchMenuLocationsUseCase();
    const {toggle: toggleMenu} = MenuState();
    const {isSearchOpened, toggleSearchOpening} = MenuLocationState();
    const {menuLocation} = MenuLocationCache();
    const {menuLocationsSearches, getMenuLocationsSearchByKeyword} = MenuLocationsSearchesLoader();
    const selectMenuLocationUseCase = SelectMenuLocationUseCase();
    const removeMenuLocationUseCase = RemoveMenuLocationUseCase();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        if (keyword !== undefined) {
            const menuLocations = getMenuLocationsSearchByKeyword(keyword);
            if (undefined !== menuLocations) {
                fetchLocations(menuLocations);
            }
        }
    }, [menuLocationsSearches]);

    useEffect(() => {
        if (menuLocation !== undefined) {
            fetchLocations([menuLocation]);
        }
    }, []);

    const menuLocations = keyword === undefined ? [] : getMenuLocationsSearchByKeyword(keyword) ?? [];
    const menuLocationData = menuLocation === undefined ? undefined : getLocationById(menuLocation);

    const handleSelectMenuLocationResult = (locationId: string) => {
        sendChooseFastLocationEvent(getUserIdUseCase.execute(), locationId);
        selectMenuLocationUseCase.execute(locationId);
        toggleSearchOpening();
    }

    const handleRemoveMenuLocation = () => {
        sendRemoveFastLocationEvent(getUserIdUseCase.execute());
        removeMenuLocationUseCase.execute();
    }

    return <div className={classes(padding('xy', '10px'))}>
        <div className={classes(`border p-[10px] rounded`, backgroundColor('secondary'))}>
            <div className={`w-full cursor-pointer flex items-center justify-between`}>
                <LabelNewLine className={classes()} onClick={toggleSearchOpening} size={"full"} label={menuLocationData === undefined ? CTA_CHOOSE_LOCATION : menuLocationData.name}/>
                {menuLocationData !== undefined && <LabelNewLine margin={`ml-[-20px]`} onClick={handleRemoveMenuLocation} label={getIcon('CircledCross', "extra-small")}/>}
            </div>
            {isSearchOpened && <>
                <div className={`border-t mt-[10px]`}/>
                <div>
                    <div className={`mt-[10px]`}>
                        <input type={`text`} onChange={(e: any) => setKeywordDebounce(() => e.target.value)} value={keywordDebounce ?? ''} className={classes(padding('xy', '10px'), backgroundColor("primary"), border(), round(), width('100%'))} placeholder={Translator.translate(LABEL_LOCATION_SEARCH_BY_KEYWORD)}/>
                    </div>
                    <div>
                        {menuLocations.map((locationId: string, index: number) => {
                            const location = getLocationById(locationId);
                            if (undefined === location) {
                                return null;
                            }

                            return <div key={`fast-location-${index}`} className={classes(padding('xy', '10px'), backgroundColor("secondary", true))} onClick={() => handleSelectMenuLocationResult(locationId)}>
                                {location.name}
                            </div>
                        })}
                    </div>
                </div>
            </>}
        </div>
        {menuLocation !== null && menuLocationData !== undefined && <div className={classes(margin('t', '10px'))}>
            <MenuLink
                link={`/locations/${menuLocation}`}
                label={CTA_SETTINGS}
                icon={'ArrowRight'}
                onClick={toggleMenu}
                useRefreshMode={true}
            />
            <MenuLink
                link={`/locations/${menuLocation}/tickets`}
                label={CTA_TICKETS}
                onClick={toggleMenu}
                icon={'ArrowRight'}
                useRefreshMode={true}
            />
            <MenuLink
                link={`/locations/${menuLocation}/schedule`}
                label={CTA_SCHEDULE}
                onClick={toggleMenu}
                icon={'ArrowRight'}
                useRefreshMode={true}
            />
        </div>}
    </div>;
}

export default MenuLocation;