import {ApiManager} from "../../Utils/ApiManager";
import {DELETE_LOCATION_OPENING_URL, LOCATION_OPENINGS_URL} from "../../Constants/ApiUrls";
import AbstractApi from "./AbstractApi";

class LocationOpeningApi {
     static async updateLocationOpenings(locationId: string, form: any) {
        try {
            const response = await ApiManager.put(LOCATION_OPENINGS_URL(locationId), form);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'updateLocationOpenings', {locationId, form});
        }
    }

     static async fetchLocationOpenings(locationId: string) {
        try {
            const response = await ApiManager.get(LOCATION_OPENINGS_URL(locationId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchLocationOpenings', {locationId});
        }
    };

     static async deleteLocationOpening(locationId: string, locationOpeningId: string) {
        try {
            const response = await ApiManager.delete(DELETE_LOCATION_OPENING_URL(locationId, locationOpeningId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'deleteLocationOpening', {locationId, locationOpeningId});
        }
    };
}

export default LocationOpeningApi;