import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {LABEL_LOCATION_COVER_IMAGE_BACKGROUND, LABEL_LOCATION_NAME} from "../../../../../../Constants/Labels";

interface InputCoverImageBackgroundInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    className?: string;
}

function InputCoverImageBackground({form, handleChange, handleBlur, handleFocus, className}: InputCoverImageBackgroundInterface) {
    return <Input
        inputData={form.fields.coverImageBackground}
        name={'coverImageBackground'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOCATION_COVER_IMAGE_BACKGROUND)}
        size={"full"}
        className={className ?? ''}
    />
}

export default InputCoverImageBackground;