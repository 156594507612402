import {useBetween} from "use-between";
import {FormHook} from "../../Hooks/Form/FormHook";
import {useEffect} from "react";
import UserApi from "../../Api/Calls/UserApi";
import AbstractApi from "../../Api/Calls/AbstractApi";
import {GetUserIdUseCase} from "./GetUserIdUseCase";
import {sendConfigurePasswordFailEvent, sendConfigurePasswordSuccessEvent} from "../../GoogleAnalytics/Events";

function ConfigurePassword() {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const temporaryPassword = urlParams.get('temporaryPassword');
    const getUserIdUseCase = GetUserIdUseCase();

    const {form, handleChange, shouldSubmit, setForm, setConstrains, handleBlur, handleFocus} = FormHook(['email', 'password', 'temporaryPassword'], {email, temporaryPassword});

    useEffect(() => {
        (async function() {
            const response = await UserApi.fetchConfigurePasswordValidator();
            if (response.success) {
                setConstrains(response.data);
            }
        })();
    }, []);

    const execute = (e: any) => {
        e.preventDefault();
        if (shouldSubmit()) {
            setForm({
                ...form,
                isLoading: true,
                error: '',
                success: ''
            });
            (async function() {
                const response = await UserApi.configurePassword(form);
                if (AbstractApi.isSuccess(response)) {
                    sendConfigurePasswordSuccessEvent(getUserIdUseCase.execute());
                    setForm({
                        ...form,
                        success: 'success_password_configured',
                    });
                } else {
                    sendConfigurePasswordFailEvent(getUserIdUseCase.execute());
                    setForm({
                        ...form,
                        isLoading: false,
                        error: response.data
                    });
                }
            })();
        } else {
            sendConfigurePasswordFailEvent(getUserIdUseCase.execute());
        }
    };

    return {form, handleChange, handleFocus, handleBlur, setForm, execute};
}

export const ConfigurePasswordUseCase = () => useBetween(ConfigurePassword);