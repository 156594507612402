import Translator from "../../../../../../Utils/Translator";
import Checkbox from "../../../../../Common/Form/Checkbox";
import {LABEL_LOCATION_ALLOW_COMMENTS, LABEL_LOCATION_QUEUE_EMERGENCY_STOP} from "../../../../../../Constants/Labels";

interface CheckboxQueueEmergencyStopInterface {
    form: any;
    handleChangeCheckbox: any;
}

function CheckboxQueueEmergencyStop({form, handleChangeCheckbox}: CheckboxQueueEmergencyStopInterface) {
    return <Checkbox
        inputData={form.fields.queueEmergencyStop}
        name={'queueEmergencyStop'}
        onChange={handleChangeCheckbox}
        label={LABEL_LOCATION_QUEUE_EMERGENCY_STOP}
        checked={form.fields.queueEmergencyStop.value}
    />;
}

export default CheckboxQueueEmergencyStop;