import React, {ReactElement} from "react";
import {
    backgroundColor,
    borderColor,
    classes,
    flex,
    height, margin, padding,
    textColor, textSize,
    width
} from "../../Libraries/Theme/Theme";
import Menu from "../Menu/Menu";
import Header from "../Header/Header";
import LocaleModal from "../../Libraries/Components/Common/Modal/LocaleModal";
import {State} from "../Menu/State";

interface PropsInterface {
    children: ReactElement|string;
    centeredContent?: boolean;
    className?: string;
}

function PageTemplate({children, centeredContent, className}: PropsInterface) {
    const {isOpened} = State();
    if (centeredContent === undefined) {
        centeredContent = false;
    }

    return <div className={`${classes(height('100vh', "min"), backgroundColor(), flex(), width('100%'), textColor(), textSize("15px"))}`}>
        <LocaleModal/>
        <Menu/>
        <div className={classes(width('100%'), !isOpened ? '' : margin('l', '300px', 'md'))}>
            <div className={`${classes(borderColor(), height('70px'))}`}>
                <Header/>
            </div>
            <div className={classes(height('100vh-90px', 'min'), padding('xy', '10px'), width('1280px', 'max'), width('100%'), flex(centeredContent ? 'center' : 'top', "center"), margin('xy', 'auto'), className ?? '')}>
                {children}
            </div>
        </div>
    </div>;
}

export default PageTemplate;