import {useBetween} from "use-between";
import {TicketsLoader} from "../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {TicketsToValidateLoader} from "../../Api/Loaders/IndexedLoaders/TicketsToValidateLoader";
import TicketApi from "../../Api/Calls/TicketApi";
import {sendChangeTicketStatusEvent} from "../../GoogleAnalytics/Events";
import {GetUserIdUseCase} from "../User/GetUserIdUseCase";

function QueueTicket() {
    const {fetch: fetchTickets} = TicketsLoader();
    const {fetch: fetchTicketsToValidate} = TicketsToValidateLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    const execute = (locationId: string, ticketId: string, callback?: any) => {
        (async function() {
            const response = await TicketApi.updateTicketStatus(locationId, ticketId, 'queue');
            if (response !== undefined) {
                sendChangeTicketStatusEvent(getUserIdUseCase.execute(), ticketId, 'backInQueue');
                fetchTickets([ticketId], true);
                fetchTicketsToValidate(locationId, true);
                if (undefined !== callback) {
                    callback();
                }
            }
        })();
    };

    return {execute};
}

export const QueueTicketUseCase = () => useBetween(QueueTicket);