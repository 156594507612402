import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {LABEL_LOCATION_CONTACT_EMAIL, LABEL_LOCATION_NAME} from "../../../../../../Constants/Labels";

interface InputContactEmailInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    size?: 'full';
    margin?: string;
    className?: string;
}

function InputContactEmail({form, handleChange, handleBlur, handleFocus, size, margin, className}: InputContactEmailInterface) {
    return <Input
        inputData={form.fields.contactEmail}
        name={'contactEmail'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_LOCATION_CONTACT_EMAIL)}
        size={size}
        className={className ?? ''}
    />
}

export default InputContactEmail;