import {useBetween} from "use-between";
import {FormHook} from "../../Hooks/Form/FormHook";
import {useEffect, useState} from "react";
import {OwnersLoader} from "../../Api/Loaders/IndexedLoaders/OwnersLoader";
import OwnerApi from "../../Api/Calls/OwnerApi";
import AbstractApi from "../../Api/Calls/AbstractApi";
import {GetUserIdUseCase} from "../User/GetUserIdUseCase";
import {sendUpdateSettingsFailEvent, sendUpdateSettingsSuccessEvent} from "../../GoogleAnalytics/Events";

const TRANSLATION_PREFIX = 'libraries__use_cases_owner_update_owner_use_case__';

function UpdateOwner() {
    const [ownerId, setOwnerId] = useState<string|undefined>();
    const {form, handleChange, handleBlur, handleFocus, shouldSubmit, setForm, setValues, setConstrains} = FormHook(['login', 'firstname', 'lastname', 'email']);
    const {fetch: fetchOwners, owners, getOwnerById} = OwnersLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        (async function() {
            const response = await OwnerApi.fetchUpdateOwnerFormValidator();
            if (AbstractApi.isSuccess(response)) {
                setConstrains(response.data);
            }
        })();
    }, []);

    useEffect(() => {
        if (undefined !== ownerId) {
            fetchOwners([ownerId]);
        }
    }, [ownerId]);

    useEffect(() => {
        if (ownerId !== undefined) {
            const owner = getOwnerById(ownerId);
            if (owner !== undefined) {
                setValues({
                    ...owner
                });
            }
        }
    }, [owners]);

    const execute = async (e: any) => {
        e.preventDefault();
        if (!shouldSubmit() || ownerId === undefined) {
            sendUpdateSettingsFailEvent(getUserIdUseCase.execute());
            return;
        }

        setForm({
            ...form,
            isLoading: true,
            error: '',
            success: '',
        })

        const response = await OwnerApi.updateOwner(ownerId, form);
        if (AbstractApi.isSuccess(response)) {
            sendUpdateSettingsSuccessEvent(getUserIdUseCase.execute());
            setForm(() => ({
                ...form,
                isLoading: false,
                success: `update_owner_success`,
                error: ``
            }));
            if (ownerId !== undefined) {
                fetchOwners([ownerId], true);
            }
        } else {
            sendUpdateSettingsFailEvent(getUserIdUseCase.execute());
            setForm(() => ({
                ...form,
                isLoading: false,
                success: ``,
                error: response.data
            }));
        }
    };

    return {setOwnerId, form, handleChange, handleBlur, handleFocus, execute, setValues, setForm, setConstrains};
}

export const UpdateOwnerUseCase = () => useBetween(UpdateOwner);